import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { useAppSelector } from '../../../redux/hooks';
import { citySetting } from '../../../utils/gameSettings';

const landing = keyframes`
  0% {
    transform: translateY(-100px);
    opacity: 0;
    animation-timing-function: ease-in;
  }   
  30% {
    transform: translateY(-100px);
    opacity: 1;
    animation-timing-function: ease-in;
  } 
  80% {
    transform: translateY(-40px);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  animation: ${landing} 1s;
  &:hover {
    transform: translateY(-10px);
  }
`;

const translate = keyframes`
from {
  transform: rotate(0deg) translateX(0) translateY(0);
}
to {
  transform: rotate(10deg) translateX(20px) translateY(-10px);
}
`;

const InteractiveGroup = styled.g`
  &:hover {
    animation: ${translate} 0.7s linear infinite alternate;
  }
`;

export const HouseOfFood: React.FC = () => {
  const { scale } = useAppSelector((state) => state.city);
  const { houseLength } = citySetting;
  const widthNormalize = houseLength / 205;

  return (
    <Wrapper>
      <svg
        width={`${205 * widthNormalize * scale}px`}
        height={`${176 * widthNormalize * scale}px`}
        viewBox="0 0 205 176"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="205" height="176" fill="none" />
        <g id="Frame" clipPath="url(#clip0_0_1)">
          <g id="Group">
            <g id="&#229;&#156;&#150;&#229;&#177;&#164; 1">
              <g id="Group_2">
                <path
                  id="Vector"
                  d="M189.44 170.42C189.44 173.18 187.2 175.42 184.44 175.42H19.53C16.77 175.42 14.53 173.18 14.53 170.42V165.08H189.45V170.42H189.44Z"
                  fill="#C6BEB8"
                />
                <g id="Group_3">
                  <g id="Group_4">
                    <path
                      id="Vector_2"
                      d="M20.42 150.44L18.13 148.03C17.44 147.32 16.57 146.41 16.65 143.78V133.08H16.27V144.23C16.27 146.74 16.88 147.6 17.93 148.69C19.47 150.28 19.87 150.89 20.54 151.6V150.74L20.42 150.45V150.44Z"
                      fill="#755925"
                    />
                    <path
                      id="Vector_3"
                      d="M19.8 136.91L21.56 136.96L22.56 164.8C22.56 164.8 22.36 166.39 20.6 166.39C18.84 166.39 18.7 164.82 18.7 164.82L19.8 136.91Z"
                      fill="#755925"
                    />
                    <path
                      id="Vector_4"
                      d="M22.06 153.32C22.84 152.51 23.28 151.4 23.28 150.26V136.65H23.88V150.6C23.88 152.4 23.18 154.15 21.95 155.42L22.06 153.31V153.32Z"
                      fill="#755925"
                    />
                    <path
                      id="Vector_5"
                      d="M23.91 145.05L25.55 143.32C26.04 142.81 26.67 142.16 26.61 140.28V132.62H26.88V140.6C26.88 142.4 26.44 143.01 25.69 143.79C24.59 144.93 24.31 145.37 23.82 145.87V145.25L23.9 145.04L23.91 145.05Z"
                      fill="#C2AE8C"
                    />
                    <path
                      id="Vector_6"
                      d="M40.84 130.85C40.84 128.61 39.28 126.75 37.23 126.36C37.06 122.28 33.82 119.02 29.84 119.02C29.28 119.02 28.73 119.09 28.21 119.21C27.61 116.96 25.64 115.3 23.28 115.3C22.1 115.3 21.02 115.72 20.16 116.41C18.98 115.02 17.25 114.14 15.33 114.14C11.83 114.14 9.00001 117.03 8.90001 120.62C8.81001 120.62 8.71001 120.61 8.62001 120.61C5.38001 120.61 2.75001 123.33 2.75001 126.69C2.75001 126.84 2.75001 126.97 2.77001 127.12C1.14001 127.96 0.0100098 129.7 0.0100098 131.71C0.0100098 133.99 1.45001 135.93 3.45001 136.59C3.45001 136.7 3.43001 136.81 3.43001 136.93C3.43001 142.14 7.50001 146.36 12.52 146.36C13.57 146.36 14.58 146.16 15.52 145.82C16.85 147.55 18.87 148.65 21.14 148.65C24.03 148.65 26.52 146.86 27.67 144.27C28.44 144.55 29.27 144.72 30.14 144.72C34.23 144.72 37.54 141.28 37.54 137.04C37.54 136.45 37.47 135.87 37.35 135.32C39.35 134.89 40.84 133.05 40.84 130.85Z"
                      fill="#236A35"
                    />
                    <path
                      id="Vector_7"
                      d="M11.92 126.75C11.92 126.66 11.92 126.57 11.92 126.48C11.92 123.89 13.95 121.79 16.45 121.79C18.3 121.79 19.9 122.95 20.6 124.6C21.18 124.09 22.26 123.32 22.95 123.18C29.1 121.86 29.12 129.18 29.31 129C29.59 128.75 30.44 124.71 27.73 122.46C23.82 119.22 21.03 121.43 20.4 121.65C19.7 120 17.76 118.51 15.91 118.51C13.41 118.51 11.48 120.19 11.48 122.78C11.48 122.87 11.31 124.3 11.32 124.39C9.95002 124.69 7.40002 126.25 7.40002 129.01C7.40002 129.93 7.60002 130.81 7.94002 131.6C8.33002 129.33 9.88002 127.48 11.94 126.74L11.92 126.75Z"
                      fill="#31833A"
                    />
                    <path
                      id="Vector_8"
                      d="M22.45 129.5C24.17 130.12 25.29 131.73 25.42 133.51C26.24 133.11 27.84 133.69 28.49 133.92C31.3 134.94 32.2 137.77 31.36 140.77C31.87 140.23 32.28 139.58 32.54 138.82C33.46 136.08 32.07 133.08 29.42 132.12C28.87 131.92 28.32 131.83 27.77 131.83C27.66 130.34 26.72 128.99 25.27 128.47C23.92 127.98 22.49 128.34 21.51 129.28C21.82 129.32 22.13 129.39 22.44 129.5H22.45Z"
                      fill="#31833A"
                    />
                  </g>
                  <g id="Group_5">
                    <path
                      id="Vector_9"
                      d="M13.02 155.26V161.06C13.02 164.14 15.67 166.64 18.95 166.64H23.18C26.45 166.64 29.11 164.14 29.11 161.06V155.26H13.02Z"
                      fill="#B51D23"
                    />
                    <path
                      id="Vector_10"
                      d="M25.33 161.06V155.26H13.02V161.06C13.02 164.14 15.67 166.64 18.95 166.64H19.4C22.67 166.64 25.33 164.14 25.33 161.06Z"
                      fill="#E8352E"
                    />
                  </g>
                </g>
                <path
                  id="Vector_11"
                  d="M149.78 36.67H145.44V50.01H149.78V36.67Z"
                  fill="#B6B2A0"
                />
                <path
                  id="Vector_12"
                  d="M60.06 36.67H55.73V50.01H60.06V36.67Z"
                  fill="#B6B2A0"
                />
                <path
                  id="Vector_13"
                  d="M155.11 43.24H50.56V48.78H155.11V43.24Z"
                  fill="#B51D23"
                />
                <path
                  id="Vector_14"
                  d="M173.52 57.59H31.84V166.58H173.52V57.59Z"
                  fill="#F8D67C"
                />
                <path
                  id="Vector_15"
                  d="M77.53 56.13H36.19V166.57H77.53V56.13Z"
                  fill="#BE7029"
                />
                <path
                  id="Vector_16"
                  d="M170.36 56.14H80.95V134.63H170.36V56.14Z"
                  fill="#BE7029"
                />
                <path
                  id="Vector_17"
                  d="M167.91 61.32H84.4V132.2H167.91V61.32Z"
                  fill="#23130E"
                />
                <path
                  id="Vector_18"
                  d="M72.61 71.26H40.3V134.44H72.61V71.26Z"
                  fill="#23130E"
                />
                <g id="Group_6" opacity="0.32">
                  <path
                    id="Vector_19"
                    d="M68.96 157.87H44.07L44.14 157.56C44.18 157.4 44.19 157.24 44.19 157.09C44.19 155.88 43.21 154.9 42 154.9C41.84 154.9 41.67 154.92 41.49 154.96L41.18 155.03V141.63L41.49 141.7C41.66 141.74 41.83 141.76 42 141.76C43.21 141.76 44.19 140.78 44.19 139.57C44.19 139.45 44.18 139.33 44.16 139.19L44.11 138.89H68.93L68.88 139.19C68.86 139.33 68.85 139.45 68.85 139.57C68.85 140.78 69.83 141.76 71.04 141.76C71.16 141.76 71.29 141.75 71.43 141.72L71.73 141.67V154.98L71.43 154.93C71.29 154.91 71.17 154.89 71.04 154.89C69.83 154.89 68.85 155.87 68.85 157.08C68.85 157.24 68.87 157.39 68.9 157.55L68.97 157.86L68.96 157.87ZM44.68 157.37H68.35C68.35 157.28 68.34 157.19 68.34 157.1C68.34 155.62 69.55 154.41 71.03 154.41C71.09 154.41 71.15 154.41 71.21 154.41V142.28C71.15 142.28 71.09 142.28 71.03 142.28C69.55 142.28 68.34 141.07 68.34 139.59C68.34 139.53 68.34 139.48 68.34 139.42H44.68C44.68 139.48 44.68 139.53 44.68 139.59C44.68 141.07 43.47 142.28 41.99 142.28C41.89 142.28 41.78 142.28 41.68 142.26V154.41C41.79 154.4 41.89 154.39 41.99 154.39C43.47 154.39 44.68 155.6 44.68 157.08C44.68 157.17 44.68 157.26 44.67 157.35L44.68 157.37Z"
                    fill="#321307"
                  />
                </g>
                <path
                  id="Vector_20"
                  opacity="0.15"
                  d="M149.69 48.04H84.4V107.62L149.69 48.04Z"
                  fill="#F5F5F5"
                />
                <path
                  id="Vector_21"
                  opacity="0.15"
                  d="M40.31 71.26V92.8L64.46 71.26H40.31Z"
                  fill="#F5F5F5"
                />
                <path
                  id="Vector_22"
                  d="M72.63 53.73H40.32V73.94H72.63V53.73Z"
                  fill="#321307"
                />
                <path
                  id="Vector_23"
                  d="M148 55.96H31.83L50.49 48.04H129.34L148 55.96Z"
                  fill="#F2BE48"
                />
                <path
                  id="Vector_24"
                  d="M44.73 65.01C44.73 68.52 41.89 71.37 38.39 71.37H38.17C34.67 71.37 31.83 68.52 31.83 65.01V55.96H44.74V65.01H44.73Z"
                  fill="#F1AA43"
                />
                <path
                  id="Vector_25"
                  d="M57.64 65.01C57.64 68.52 54.8 71.37 51.29 71.37H51.07C47.57 71.37 44.73 68.52 44.73 65.01V55.96H57.64V65.01Z"
                  fill="#E6E7E8"
                />
                <path
                  id="Vector_26"
                  d="M70.54 65.01C70.54 68.52 67.7 71.37 64.19 71.37H63.97C60.47 71.37 57.62 68.52 57.62 65.01V55.96H70.53V65.01H70.54Z"
                  fill="#F1AA43"
                />
                <path
                  id="Vector_27"
                  d="M83.45 65.01C83.45 68.52 80.61 71.37 77.1 71.37H76.88C73.38 71.37 70.53 68.52 70.53 65.01V55.96H83.44V65.01H83.45Z"
                  fill="#E6E7E8"
                />
                <path
                  id="Vector_28"
                  d="M96.36 65.01C96.36 68.52 93.52 71.37 90.02 71.37H89.8C86.3 71.37 83.46 68.52 83.46 65.01V55.96H96.37V65.01H96.36Z"
                  fill="#F1AA43"
                />
                <path
                  id="Vector_29"
                  d="M109.27 65.01C109.27 68.52 106.43 71.37 102.92 71.37H102.7C99.2 71.37 96.35 68.52 96.35 65.01V55.96H109.26V65.01H109.27Z"
                  fill="#E6E7E8"
                />
                <path
                  id="Vector_30"
                  d="M122.17 65.01C122.17 68.52 119.33 71.37 115.82 71.37H115.6C112.1 71.37 109.25 68.52 109.25 65.01V55.96H122.16V65.01H122.17Z"
                  fill="#F1AA43"
                />
                <path
                  id="Vector_31"
                  d="M135.08 65.01C135.08 68.52 132.24 71.37 128.73 71.37H128.51C125.01 71.37 122.16 68.52 122.16 65.01V55.96H135.07V65.01H135.08Z"
                  fill="#E6E7E8"
                />
                <path
                  id="Vector_32"
                  d="M147.99 65.01C147.99 68.52 145.15 71.37 141.65 71.37H141.43C137.93 71.37 135.09 68.52 135.09 65.01V55.96H148V65.01H147.99Z"
                  fill="#F1AA43"
                />
                <path
                  id="Vector_33"
                  d="M105.16 48.04H95.28L96.37 55.96H109.28L105.16 48.04Z"
                  fill="#E8F0F3"
                />
                <path
                  id="Vector_34"
                  d="M122.07 48.04H114.03L122.17 55.96H135.08L122.07 48.04Z"
                  fill="#E8F0F3"
                />
                <path
                  id="Vector_35"
                  d="M74.68 48.04H84.54L83.44 55.97H70.53L74.68 48.04Z"
                  fill="#E8F0F3"
                />
                <path
                  id="Vector_36"
                  d="M57.76 48.04H65.8L57.64 55.96H44.73L57.76 48.04Z"
                  fill="#E8F0F3"
                />
                <path
                  id="Vector_37"
                  d="M148 55.96H31.83L50.49 48.04H129.34L148 55.96Z"
                  fill="#E8352E"
                />
                <path
                  id="Vector_38"
                  d="M135.08 65.01C135.08 68.52 132.24 71.37 128.73 71.37H128.51C125.01 71.37 122.16 68.52 122.16 65.01V55.96H135.07V65.01H135.08Z"
                  fill="#E6E7E8"
                />
                <path
                  id="Vector_39"
                  d="M147.99 65.01C147.99 68.52 145.15 71.37 141.65 71.37H141.43C137.93 71.37 135.09 68.52 135.09 65.01V55.96H148V65.01H147.99Z"
                  fill="#F1AA43"
                />
                <path
                  id="Vector_40"
                  d="M122.07 48.04H114.03L122.17 55.96H135.08L122.07 48.04Z"
                  fill="#E8F0F3"
                />
                <path
                  id="Vector_41"
                  d="M173.57 56.2L57.64 55.96L76.3 48.04H155.14L173.57 56.2Z"
                  fill="#F2BE48"
                />
                <path
                  id="Vector_42"
                  d="M135.07 65.01C135.07 68.52 132.23 71.37 128.72 71.37H128.5C125 71.37 122.15 68.52 122.15 65.01V55.96H135.06V65.01H135.07Z"
                  fill="#E6E7E8"
                />
                <path
                  id="Vector_43"
                  d="M147.98 65.01C147.98 68.52 145.14 71.37 141.63 71.37H141.41C137.91 71.37 135.06 68.52 135.06 65.01V55.96H147.97V65.01H147.98Z"
                  fill="#F1AA43"
                />
                <path
                  id="Vector_44"
                  d="M160.89 65.01C160.89 68.52 158.05 71.37 154.54 71.37H154.32C150.82 71.37 147.97 68.52 147.97 65.01V55.96H160.88V65.01H160.89Z"
                  fill="#E6E7E8"
                />
                <path
                  id="Vector_45"
                  d="M173.56 65.25C173.56 68.76 170.95 71.37 167.45 71.37H167.23C163.73 71.37 160.89 68.52 160.89 65.01V55.96L173.56 56.19V65.24V65.25Z"
                  fill="#F1AA43"
                />
                <path
                  id="Vector_46"
                  d="M130.96 48.04H121.08L122.17 55.96H135.08L130.96 48.04Z"
                  fill="#E8F0F3"
                />
                <path
                  id="Vector_47"
                  d="M147.87 48.04H139.84L147.98 55.96H160.89L147.87 48.04Z"
                  fill="#E8F0F3"
                />
                <path
                  id="Vector_48"
                  d="M173.57 56.2L57.64 55.96L65.8 48.04H155.14L173.57 56.2Z"
                  fill="#E8352E"
                />
                <g
                  id="Vector_49"
                  style={{ mixBlendMode: 'multiply' }}
                  opacity="0.29"
                >
                  <path
                    d="M160.89 60.37H31.82V69.42C31.82 72.93 34.66 75.78 38.16 75.78H38.38C41.88 75.78 44.72 72.93 44.72 69.42C44.72 72.93 47.56 75.78 51.06 75.78H51.28C54.78 75.78 57.63 72.93 57.63 69.42C57.63 72.93 60.47 75.78 63.98 75.78H64.2C67.7 75.78 70.55 72.93 70.55 69.42C70.55 72.93 73.39 75.78 76.9 75.78H77.12C80.62 75.78 83.47 72.93 83.47 69.42C83.47 72.93 86.31 75.78 89.81 75.78H90.03C93.53 75.78 96.37 72.93 96.37 69.42C96.37 72.93 99.21 75.78 102.72 75.78H102.94C106.44 75.78 109.29 72.93 109.29 69.42C109.29 72.93 112.13 75.78 115.64 75.78H115.86C119.3 75.78 122.1 73.04 122.2 69.62C122.3 73.04 125.1 75.78 128.54 75.78H128.76C132.26 75.78 135.11 72.93 135.11 69.42C135.11 72.93 137.95 75.78 141.46 75.78H141.68C145.18 75.78 148.03 72.93 148.03 69.42C148.03 72.93 150.87 75.78 154.38 75.78H154.6C158.1 75.78 160.95 72.93 160.95 69.42C160.95 72.93 163.79 75.78 167.29 75.78H167.51C171.01 75.78 173.62 73.17 173.62 69.66V60.61L160.95 60.38L160.89 60.37Z"
                    fill="#050000"
                  />
                </g>
                <path
                  id="Vector_50"
                  d="M130.96 48.04H120.08L122.17 55.96H135.08L130.96 48.04Z"
                  fill="#E8F0F3"
                />
                <path
                  id="Vector_51"
                  d="M147.87 48.04H139.84L147.98 55.96H160.89L147.87 48.04Z"
                  fill="#E8F0F3"
                />
                <path
                  id="Vector_52"
                  d="M74.68 48.04H84.54L83.44 55.97H70.53L74.68 48.04Z"
                  fill="#E8F0F3"
                />
                <path
                  id="Vector_53"
                  d="M57.76 48.04H65.8L57.64 55.96H44.73L57.76 48.04Z"
                  fill="#E8F0F3"
                />
                <path
                  id="Vector_54"
                  d="M108.79 48.04H96.36L96.37 55.96H109.28L108.79 48.04Z"
                  fill="#E8F0F3"
                />
                <path
                  id="Vector_55"
                  d="M73.24 106.84H39.92V107.87H73.24V106.84Z"
                  fill="#EFA234"
                />
                <path
                  id="Vector_56"
                  d="M40.09 107.35C40.09 107.94 39.61 108.42 39.02 108.42C38.43 108.42 37.96 107.94 37.96 107.35C37.96 106.76 38.44 106.28 39.02 106.28C39.6 106.28 40.09 106.76 40.09 107.35Z"
                  fill="#EFA234"
                />
                <path
                  id="Vector_57"
                  d="M74.87 107.35C74.87 107.94 74.39 108.42 73.8 108.42C73.21 108.42 72.73 107.94 72.73 107.35C72.73 106.76 73.21 106.28 73.8 106.28C74.39 106.28 74.87 106.76 74.87 107.35Z"
                  fill="#EFA234"
                />
                <path
                  id="Vector_58"
                  d="M73.24 111.72H39.92V112.75H73.24V111.72Z"
                  fill="#EFA234"
                />
                <path
                  id="Vector_59"
                  d="M40.09 112.23C40.09 112.82 39.61 113.3 39.02 113.3C38.43 113.3 37.96 112.82 37.96 112.23C37.96 111.64 38.44 111.16 39.02 111.16C39.6 111.16 40.09 111.64 40.09 112.23Z"
                  fill="#EFA234"
                />
                <path
                  id="Vector_60"
                  d="M74.87 112.23C74.87 112.82 74.39 113.3 73.8 113.3C73.21 113.3 72.73 112.82 72.73 112.23C72.73 111.64 73.21 111.16 73.8 111.16C74.39 111.16 74.87 111.64 74.87 112.23Z"
                  fill="#EFA234"
                />
                <path
                  id="Vector_61"
                  d="M113.53 91.49C115.38 86.68 120.04 83.27 125.5 83.27C130.96 83.27 135.92 86.91 137.64 91.96L141.76 90.56C139.46 83.79 133.05 78.92 125.5 78.92C117.95 78.92 111.94 83.49 109.46 89.93L113.53 91.49Z"
                  fill="#F5D85D"
                />
                <path
                  id="Vector_62"
                  d="M119.89 94.31C120.77 92.03 122.97 90.42 125.56 90.42C128.15 90.42 130.49 92.14 131.31 94.53L133.26 93.87C132.17 90.66 129.13 88.36 125.56 88.36C121.99 88.36 119.14 90.52 117.97 93.57L119.89 94.31Z"
                  fill="#F5D85D"
                />
                <path
                  id="Vector_63"
                  d="M136.32 104.79H114.92V106.22H136.32V104.79Z"
                  fill="#F5D85D"
                />
                <path
                  id="Vector_64"
                  d="M131.43 107.89H119.81V109.85H131.43V107.89Z"
                  fill="#F5D85D"
                />
                <path
                  id="Vector_65"
                  d="M67.01 93.86H46.65V100.25H67.01V93.86Z"
                  fill="#B51D23"
                />
                <path
                  id="Vector_66"
                  d="M63.27 95.45H50.41V98.65H63.27V95.45Z"
                  fill="#E8F0F3"
                />
                <g id="Group_7">
                  <g id="Group_8">
                    <path
                      id="Vector_67"
                      d="M112.6 139.57V136.17C112.6 135.54 112.09 135.04 111.47 135.04C110.85 135.04 110.34 135.55 110.34 136.17V139.57H112.61H112.6Z"
                      fill="#F5F5F5"
                    />
                    <path
                      id="Vector_68"
                      d="M111.46 135.03V139.57H112.59V136.17C112.59 135.54 112.08 135.04 111.46 135.04V135.03Z"
                      fill="#C2CDD0"
                    />
                  </g>
                  <g id="Group_9">
                    <path
                      id="Vector_69"
                      d="M114.86 139.57V136.17C114.86 135.54 114.35 135.04 113.73 135.04C113.11 135.04 112.6 135.55 112.6 136.17V139.57H114.87H114.86Z"
                      fill="#F5F5F5"
                    />
                    <path
                      id="Vector_70"
                      d="M113.73 135.03V139.57H114.86V136.17C114.86 135.54 114.35 135.04 113.73 135.04V135.03Z"
                      fill="#C2CDD0"
                    />
                  </g>
                  <path
                    id="Vector_71"
                    d="M111.13 135.51C111.13 135.56 111.09 135.61 111.04 135.61C110.99 135.61 110.95 135.57 110.95 135.51C110.95 135.46 110.99 135.42 111.04 135.42C111.09 135.42 111.13 135.46 111.13 135.51Z"
                    fill="#23130E"
                  />
                  <path
                    id="Vector_72"
                    d="M111.98 135.51C111.98 135.56 111.94 135.61 111.89 135.61C111.84 135.61 111.8 135.57 111.8 135.51C111.8 135.46 111.84 135.42 111.89 135.42C111.94 135.42 111.98 135.46 111.98 135.51Z"
                    fill="#23130E"
                  />
                  <path
                    id="Vector_73"
                    d="M111.57 135.88C111.57 135.93 111.53 135.97 111.48 135.97C111.43 135.97 111.39 135.93 111.39 135.88C111.39 135.83 111.43 135.79 111.48 135.79C111.53 135.79 111.57 135.83 111.57 135.88Z"
                    fill="#23130E"
                  />
                  <path
                    id="Vector_74"
                    d="M113.39 135.51C113.39 135.56 113.35 135.61 113.3 135.61C113.25 135.61 113.21 135.57 113.21 135.51C113.21 135.46 113.25 135.42 113.3 135.42C113.35 135.42 113.39 135.46 113.39 135.51Z"
                    fill="#23130E"
                  />
                  <path
                    id="Vector_75"
                    d="M114.24 135.51C114.24 135.56 114.2 135.61 114.15 135.61C114.1 135.61 114.05 135.57 114.05 135.51C114.05 135.46 114.09 135.42 114.15 135.42C114.2 135.42 114.24 135.46 114.24 135.51Z"
                    fill="#23130E"
                  />
                  <path
                    id="Vector_76"
                    d="M113.83 135.88C113.83 135.93 113.79 135.97 113.74 135.97C113.69 135.97 113.65 135.93 113.65 135.88C113.65 135.83 113.69 135.79 113.74 135.79C113.79 135.79 113.83 135.83 113.83 135.88Z"
                    fill="#23130E"
                  />
                </g>
                <g id="Group_10">
                  <path
                    id="Vector_77"
                    d="M133.1 138.7C133.32 138.7 133.42 138.44 133.27 138.29L126.23 131.25L119.19 138.29C119.04 138.44 119.15 138.7 119.36 138.7H133.09H133.1Z"
                    fill="#F5F5F5"
                  />
                  <path
                    id="Vector_78"
                    d="M130.56 139.43V138.4C130.56 136.01 128.62 134.07 126.23 134.07C123.84 134.07 121.9 136.01 121.9 138.4V139.43H130.56Z"
                    fill="#C1D3D9"
                  />
                </g>
                <g id="Group_11" opacity="0.55">
                  <path
                    id="Vector_79"
                    d="M60.71 88.26C60.71 88.45 60.55 88.61 60.36 88.61H53.31C53.12 88.61 52.96 88.45 52.96 88.26C52.96 88.07 53.12 87.91 53.31 87.91H60.36C60.55 87.91 60.71 88.07 60.71 88.26Z"
                    fill="#F5F5F5"
                  />
                  <path
                    id="Vector_80"
                    d="M56.86 83.73C54.73 83.73 52.99 85.38 52.85 87.47C52.85 87.63 52.97 87.75 53.13 87.75H60.61C60.77 87.75 60.9 87.62 60.89 87.47C60.74 85.38 59 83.73 56.88 83.73H56.86ZM54.1 86.99C54 87.09 53.84 87.09 53.75 86.99C53.65 86.89 53.65 86.73 53.75 86.63C53.85 86.53 54.23 86.5 54.23 86.5C54.23 86.5 54.2 86.88 54.1 86.98V86.99ZM55.32 85.43C55.22 85.53 55.06 85.53 54.97 85.43C54.87 85.33 54.84 84.95 54.84 84.95C54.84 84.95 55.22 84.98 55.32 85.08C55.42 85.18 55.42 85.34 55.32 85.43ZM56.78 87.07C56.64 87.07 56.53 86.96 56.53 86.82C56.53 86.68 56.78 86.39 56.78 86.39C56.78 86.39 57.03 86.68 57.03 86.82C57.03 86.96 56.92 87.07 56.78 87.07ZM56.78 84.78C56.78 84.78 56.53 84.49 56.53 84.35C56.53 84.21 56.64 84.1 56.78 84.1C56.92 84.1 57.03 84.21 57.03 84.35C57.03 84.49 56.78 84.78 56.78 84.78ZM58.76 85.43C58.66 85.53 58.5 85.53 58.41 85.43C58.31 85.33 58.31 85.17 58.41 85.08C58.51 84.98 58.89 84.95 58.89 84.95C58.89 84.95 58.86 85.33 58.76 85.43ZM59.98 86.99C59.88 87.09 59.72 87.09 59.63 86.99C59.53 86.89 59.5 86.51 59.5 86.51C59.5 86.51 59.88 86.54 59.98 86.64C60.08 86.74 60.08 86.9 59.98 87V86.99Z"
                    fill="#F5F5F5"
                  />
                  <path
                    id="Vector_81"
                    d="M60.14 90.4H56.34L55.88 90.86C55.8 90.94 55.67 90.94 55.59 90.86L55.13 90.4H53.55C53.39 90.4 53.27 90.53 53.27 90.68V91.26C53.27 91.42 53.4 91.54 53.55 91.54H60.16C60.32 91.54 60.45 91.41 60.45 91.26V90.68C60.45 90.52 60.32 90.4 60.16 90.4H60.14Z"
                    fill="#F5F5F5"
                  />
                  <path
                    id="Vector_82"
                    d="M53.22 88.78V89.08C53.22 89.23 53.25 89.32 53.32 89.42C53.37 89.49 53.47 89.51 53.56 89.51C53.65 89.51 53.74 89.48 53.8 89.43L53.94 89.32C53.94 89.32 54.04 89.27 54.1 89.27C54.16 89.27 54.22 89.29 54.26 89.32L54.4 89.43C54.53 89.53 54.75 89.53 54.88 89.43L55.02 89.32C55.11 89.25 55.25 89.25 55.34 89.32L55.48 89.43C55.54 89.48 55.63 89.51 55.72 89.51C55.81 89.51 55.9 89.48 55.96 89.43L56.1 89.32C56.19 89.25 56.33 89.25 56.42 89.32L56.56 89.43C56.69 89.53 56.91 89.53 57.04 89.43L57.18 89.32C57.27 89.25 57.41 89.25 57.5 89.32L57.53 89.34L57.64 89.43C57.7 89.48 57.79 89.51 57.88 89.51C57.97 89.51 58.06 89.48 58.12 89.43L58.26 89.32C58.26 89.32 58.36 89.27 58.42 89.27C58.48 89.27 58.54 89.29 58.58 89.32L58.72 89.43C58.78 89.48 58.87 89.51 58.96 89.51C59.05 89.51 59.14 89.48 59.2 89.43L59.34 89.32C59.43 89.25 59.57 89.25 59.66 89.32L59.8 89.43C59.86 89.48 59.95 89.51 60.04 89.51C60.13 89.51 60.22 89.48 60.28 89.43C60.28 89.43 60.44 89.31 60.44 89.02V88.78H53.19H53.22Z"
                    fill="#F5F5F5"
                  />
                  <path
                    id="Vector_83"
                    d="M54.4 89.73L54.26 89.62C54.26 89.62 54.16 89.57 54.1 89.57C54.04 89.57 53.98 89.59 53.94 89.62L53.8 89.73C53.74 89.78 53.65 89.81 53.56 89.81C53.47 89.81 53.37 89.79 53.32 89.72C53.25 89.63 53.22 89.53 53.22 89.38C53.12 89.46 53.06 89.58 53.06 89.73C53.06 89.99 53.27 90.21 53.54 90.21H54.94L54.52 89.79C54.52 89.79 54.44 89.76 54.41 89.73H54.4Z"
                    fill="#F5F5F5"
                  />
                  <path
                    id="Vector_84"
                    d="M60.47 89.38C60.45 89.62 60.31 89.73 60.31 89.73C60.25 89.78 60.16 89.81 60.07 89.81C59.98 89.81 59.89 89.78 59.83 89.73L59.69 89.62C59.6 89.55 59.46 89.55 59.37 89.62L59.23 89.73C59.16 89.78 59.08 89.81 58.99 89.81C58.9 89.81 58.81 89.78 58.75 89.73L58.61 89.62C58.61 89.62 58.51 89.57 58.45 89.57C58.39 89.57 58.33 89.59 58.29 89.62L58.15 89.73C58.09 89.78 58 89.81 57.91 89.81C57.82 89.81 57.73 89.78 57.67 89.73L57.56 89.64L57.53 89.62C57.44 89.55 57.3 89.55 57.21 89.62L57.07 89.73C57.07 89.73 57 89.78 56.96 89.79L56.54 90.21H60.16C60.42 90.21 60.64 90 60.64 89.73C60.64 89.59 60.58 89.47 60.49 89.38H60.47Z"
                    fill="#F5F5F5"
                  />
                </g>
                <path
                  id="Vector_85"
                  d="M70.54 65.01C70.54 68.52 67.7 71.37 64.19 71.37H63.97C60.47 71.37 57.62 68.52 57.62 65.01V55.96H70.53V65.01H70.54Z"
                  fill="#B51D23"
                />
                <path
                  id="Vector_86"
                  d="M83.45 65.01C83.45 68.52 80.61 71.37 77.1 71.37H76.88C73.38 71.37 70.53 68.52 70.53 65.01V55.96H83.44V65.01H83.45Z"
                  fill="#CAD0D7"
                />
                <path
                  id="Vector_87"
                  d="M96.36 65.01C96.36 68.52 93.52 71.37 90.02 71.37H89.8C86.3 71.37 83.46 68.52 83.46 65.01V55.96H96.37V65.01H96.36Z"
                  fill="#B51D23"
                />
                <path
                  id="Vector_88"
                  d="M109.27 65.01C109.27 68.52 106.43 71.37 102.92 71.37H102.7C99.2 71.37 96.35 68.52 96.35 65.01V55.96H109.26V65.01H109.27Z"
                  fill="#CAD0D7"
                />
                <path
                  id="Vector_89"
                  d="M122.17 65.01C122.17 68.52 119.33 71.37 115.82 71.37H115.6C112.1 71.37 109.25 68.52 109.25 65.01V55.96H122.16V65.01H122.17Z"
                  fill="#B51D23"
                />
                <path
                  id="Vector_90"
                  d="M135.07 65.01C135.07 68.52 132.23 71.37 128.72 71.37H128.5C125 71.37 122.15 68.52 122.15 65.01V55.96H135.06V65.01H135.07Z"
                  fill="#CAD0D7"
                />
                <path
                  id="Vector_91"
                  d="M147.98 65.01C147.98 68.52 145.14 71.37 141.63 71.37H141.41C137.91 71.37 135.06 68.52 135.06 65.01V55.96H147.97V65.01H147.98Z"
                  fill="#B51D23"
                />
                <path
                  id="Vector_92"
                  d="M160.89 65.01C160.89 68.52 158.05 71.37 154.54 71.37H154.32C150.82 71.37 147.97 68.52 147.97 65.01V55.96H160.88V65.01H160.89Z"
                  fill="#CAD0D7"
                />
                <path
                  id="Vector_93"
                  d="M173.56 65.25C173.56 68.76 170.95 71.37 167.45 71.37H167.23C163.73 71.37 160.89 68.52 160.89 65.01V55.96L173.56 56.19V65.24V65.25Z"
                  fill="#B51D23"
                />
                <g
                  id="Vector_94"
                  style={{ mixBlendMode: 'multiply' }}
                  opacity="0.29"
                >
                  <path
                    d="M45.14 56.19H36.21V166.58H45.14V56.19Z"
                    fill="#050000"
                  />
                </g>
                <g id="Group_12">
                  <path
                    id="Vector_95"
                    d="M76.38 122.36C76.38 123.38 75.55 124.21 74.53 124.21C73.51 124.21 72.68 123.38 72.68 122.36C72.68 121.34 73.51 120.51 74.53 120.51C75.55 120.51 76.38 121.34 76.38 122.36Z"
                    fill="#D8A026"
                  />
                  <path
                    id="Vector_96"
                    d="M75.49 122.36C75.49 122.74 75.18 123.04 74.81 123.04H69.44C69.06 123.04 68.76 122.73 68.76 122.36C68.76 121.98 69.07 121.68 69.44 121.68H74.81C75.19 121.68 75.49 121.99 75.49 122.36Z"
                    fill="#F5C44A"
                  />
                </g>
                <path
                  id="Vector_97"
                  d="M44.73 65.01C44.73 68.52 41.89 71.37 38.39 71.37H38.17C34.67 71.37 31.83 68.52 31.83 65.01V55.96H44.74V65.01H44.73Z"
                  fill="#B51D23"
                />
                <path
                  id="Vector_98"
                  d="M57.64 65.01C57.64 68.52 54.8 71.37 51.29 71.37H51.07C47.57 71.37 44.73 68.52 44.73 65.01V55.96H57.64V65.01Z"
                  fill="#CAD0D7"
                />
                <g id="Group_13">
                  <path
                    id="Vector_99"
                    d="M148.29 154.61C151.33 154.61 154.26 154.5 156.52 154.31L156.47 153.72C154.22 153.91 151.32 154.01 148.29 154.01C146.03 154.01 143.87 153.95 142.05 153.84L142.01 154.43C143.84 154.54 146.01 154.6 148.29 154.6V154.61Z"
                    fill="#8DA993"
                  />
                  <path
                    id="Vector_100"
                    d="M158.15 171.75H158.61C158.98 171.75 159.34 171.59 159.58 171.31C159.83 171.02 159.93 170.65 159.87 170.27C159.33 166.79 157.55 155.26 157.17 152.99L156.15 153.16C156.53 155.42 158.31 166.95 158.85 170.42C158.87 170.52 158.82 170.59 158.79 170.62C158.76 170.65 158.7 170.7 158.61 170.7H158.15V171.74V171.75Z"
                    fill="#8DA993"
                  />
                  <path
                    id="Vector_101"
                    d="M158.15 170.72H155.93C155.6 170.72 155.34 170.99 155.34 171.31V171.46C155.34 171.62 155.47 171.76 155.64 171.76H158.16V170.72H158.15Z"
                    fill="#040000"
                  />
                  <path
                    id="Vector_102"
                    d="M140.5 171.75H140.04C139.67 171.75 139.31 171.59 139.07 171.31C138.82 171.02 138.72 170.65 138.78 170.27C139.32 166.79 141.1 155.26 141.48 152.99L142.5 153.16C142.12 155.42 140.34 166.95 139.8 170.42C139.78 170.52 139.83 170.59 139.86 170.62C139.89 170.65 139.95 170.7 140.04 170.7H140.5V171.74V171.75Z"
                    fill="#8DA993"
                  />
                  <path
                    id="Vector_103"
                    d="M140.5 170.72H142.72C143.05 170.72 143.31 170.99 143.31 171.31V171.46C143.31 171.62 143.18 171.76 143.01 171.76H140.49V170.72H140.5Z"
                    fill="#040000"
                  />
                  <path
                    id="Vector_104"
                    d="M148.36 153.41C151.12 153.41 153.88 153.32 155.94 153.14C156.87 153.06 157.19 152.49 157.41 151.89C159.64 145.58 159.88 133.39 159.88 128.44C159.88 128.03 159.55 127.7 159.14 127.7C158.73 127.7 158.4 128.03 158.4 128.44C158.4 133.31 158.17 145.3 156.02 151.4C155.96 151.56 155.93 151.62 155.91 151.65C155.89 151.65 155.86 151.66 155.82 151.66C151.78 152.01 144.96 152.01 140.93 151.66C140.52 151.62 140.16 151.92 140.13 152.33C140.09 152.74 140.4 153.1 140.8 153.13C142.85 153.31 145.61 153.4 148.37 153.4L148.36 153.41Z"
                    fill="#DB1716"
                  />
                  <path
                    id="Vector_105"
                    d="M147.93 154.11C150.85 154.11 153.81 154.01 156.13 153.81C156.96 153.74 157.67 153.2 157.95 152.42C160.21 146.24 160.55 134.66 160.55 128.31C160.55 127.9 160.22 127.57 159.81 127.57C159.4 127.57 159.07 127.9 159.07 128.31C159.07 133.35 158.82 145.73 156.56 151.91C156.47 152.14 156.26 152.31 156 152.33C151.55 152.71 144.73 152.72 140.48 152.36C140.07 152.33 139.71 152.63 139.68 153.03C139.65 153.44 139.95 153.8 140.35 153.83C142.45 154.01 145.16 154.1 147.92 154.1L147.93 154.11Z"
                    fill="#12808A"
                  />
                </g>
                <g id="Group_14">
                  <path
                    id="Vector_106"
                    d="M99.83 154.61C96.78 154.61 93.86 154.5 91.6 154.31L91.65 153.72C93.9 153.91 96.8 154.01 99.83 154.01C102.09 154.01 104.25 153.95 106.07 153.84L106.11 154.43C104.28 154.54 102.11 154.6 99.83 154.6V154.61Z"
                    fill="#8DA993"
                  />
                  <path
                    id="Vector_107"
                    d="M89.97 171.75H89.51C89.14 171.75 88.78 171.59 88.54 171.31C88.29 171.02 88.19 170.65 88.24 170.27C88.78 166.79 90.56 155.26 90.94 152.99L91.96 153.16C91.58 155.42 89.8 166.95 89.26 170.42C89.24 170.52 89.29 170.59 89.32 170.62C89.35 170.65 89.41 170.7 89.5 170.7H89.96V171.74L89.97 171.75Z"
                    fill="#8DA993"
                  />
                  <path
                    id="Vector_108"
                    d="M89.97 170.72H92.19C92.52 170.72 92.78 170.99 92.78 171.31V171.46C92.78 171.62 92.65 171.76 92.49 171.76H89.97V170.72Z"
                    fill="#040000"
                  />
                  <path
                    id="Vector_109"
                    d="M107.63 171.75H108.09C108.46 171.75 108.81 171.59 109.06 171.31C109.31 171.02 109.41 170.65 109.35 170.27C108.81 166.79 107.03 155.26 106.65 152.99L105.63 153.16C106.01 155.42 107.79 166.95 108.33 170.42C108.35 170.52 108.3 170.59 108.27 170.62C108.24 170.65 108.18 170.7 108.09 170.7H107.63V171.74V171.75Z"
                    fill="#8DA993"
                  />
                  <path
                    id="Vector_110"
                    d="M107.63 170.72H105.41C105.08 170.72 104.82 170.99 104.82 171.31V171.46C104.82 171.62 104.95 171.76 105.12 171.76H107.64V170.72H107.63Z"
                    fill="#040000"
                  />
                  <path
                    id="Vector_111"
                    d="M99.76 153.41C97 153.41 94.24 153.32 92.18 153.14C91.25 153.06 90.93 152.49 90.71 151.89C88.48 145.58 88.24 133.39 88.24 128.44C88.24 128.03 88.57 127.7 88.98 127.7C89.39 127.7 89.72 128.03 89.72 128.44C89.72 133.31 89.95 145.3 92.1 151.4C92.16 151.56 92.19 151.62 92.21 151.65C92.23 151.65 92.26 151.66 92.3 151.66C96.34 152.01 103.16 152.01 107.19 151.66C107.6 151.62 107.96 151.92 107.99 152.33C108.03 152.74 107.72 153.1 107.32 153.13C105.27 153.31 102.51 153.4 99.75 153.4L99.76 153.41Z"
                    fill="#DB1716"
                  />
                  <path
                    id="Vector_112"
                    d="M100.19 154.11C97.27 154.11 94.31 154.01 91.99 153.81C91.16 153.74 90.45 153.2 90.17 152.42C87.91 146.24 87.57 134.66 87.57 128.31C87.57 127.9 87.9 127.57 88.31 127.57C88.72 127.57 89.05 127.9 89.05 128.31C89.05 133.35 89.3 145.73 91.56 151.91C91.65 152.14 91.87 152.31 92.12 152.33C96.57 152.71 103.39 152.72 107.64 152.36C108.05 152.33 108.41 152.63 108.44 153.03C108.47 153.44 108.17 153.8 107.77 153.83C105.67 154.01 102.96 154.1 100.2 154.1L100.19 154.11Z"
                    fill="#12808A"
                  />
                </g>
                <g id="Group_15">
                  <path
                    id="Vector_113"
                    d="M125.38 140.74H122.02V171.01H125.38V140.74Z"
                    fill="#040000"
                  />
                  <path
                    id="Vector_114"
                    d="M126.63 143.57C126.63 144.33 125.73 144.95 124.61 144.95H122.79C121.68 144.95 120.77 144.33 120.77 143.57V140.74H126.62V143.57H126.63Z"
                    fill="#8EA994"
                  />
                  <path
                    id="Vector_115"
                    d="M126.63 171.25H120.78V166.14C120.78 165.38 121.68 164.76 122.8 164.76H124.62C125.73 164.76 126.64 165.38 126.64 166.14V171.25H126.63Z"
                    fill="#8EA994"
                  />
                  <path
                    id="Vector_116"
                    d="M144.47 139.18H102.93V141.3H144.47V139.18Z"
                    fill="#DB1716"
                  />
                  <path
                    id="Vector_117"
                    d="M135 170.74H112.41V171.75H135V170.74Z"
                    fill="#525252"
                  />
                </g>
                <g id="Group_16">
                  <path
                    id="Vector_118"
                    d="M164.67 38.59H49.25V17.25C49.25 16.2 50.16 15.35 51.29 15.35H162.63C163.76 15.35 164.67 16.2 164.67 17.25V38.59Z"
                    fill="#B51D23"
                  />
                  <g id="Group_17">
                    <path
                      id="Vector_119"
                      d="M88.83 33.1V20.47H92.69C94.08 20.47 95.17 20.76 95.96 21.34C96.74 21.92 97.13 22.79 97.13 23.94C97.13 24.54 97 25.08 96.75 25.54C96.5 26.01 96.11 26.36 95.58 26.6C96.24 26.77 96.72 27.1 97.04 27.61C97.35 28.12 97.51 28.7 97.51 29.36C97.51 30.59 97.14 31.52 96.41 32.15C95.67 32.78 94.62 33.1 93.24 33.1H88.82H88.83ZM91.45 25.73H92.75C93.34 25.72 93.79 25.59 94.08 25.34C94.37 25.09 94.52 24.73 94.52 24.26C94.52 23.72 94.37 23.33 94.07 23.08C93.77 22.83 93.31 22.71 92.7 22.71H91.46V25.72L91.45 25.73ZM91.45 27.67V30.84H93.25C93.81 30.84 94.22 30.72 94.5 30.47C94.78 30.22 94.92 29.85 94.92 29.34C94.92 28.79 94.8 28.38 94.56 28.1C94.32 27.82 93.94 27.68 93.4 27.68H91.45V27.67Z"
                      fill="white"
                    />
                    <path
                      id="Vector_120"
                      d="M109.4 20.47V28.75C109.4 30.19 108.97 31.31 108.12 32.1C107.27 32.89 106.15 33.29 104.77 33.29C103.39 33.29 102.25 32.89 101.4 32.1C100.55 31.31 100.12 30.19 100.12 28.75V20.47H102.74V28.75C102.74 29.53 102.92 30.1 103.27 30.47C103.63 30.84 104.13 31.03 104.77 31.03C105.41 31.03 105.91 30.84 106.26 30.48C106.61 30.11 106.78 29.53 106.78 28.75V20.47H109.4Z"
                      fill="white"
                    />
                    <path
                      id="Vector_121"
                      d="M114.92 28.26V33.1H112.3V20.47H116.63C117.93 20.47 118.96 20.81 119.73 21.49C120.49 22.17 120.87 23.09 120.87 24.27C120.87 24.92 120.72 25.48 120.41 25.95C120.1 26.42 119.65 26.8 119.06 27.09C119.75 27.31 120.24 27.68 120.55 28.19C120.85 28.7 121 29.34 121 30.1V30.9C121 31.23 121.04 31.61 121.13 32.03C121.22 32.45 121.37 32.75 121.58 32.93V33.1H118.89C118.68 32.92 118.55 32.6 118.48 32.15C118.42 31.69 118.39 31.27 118.39 30.88V30.12C118.39 29.52 118.25 29.06 117.96 28.74C117.68 28.42 117.26 28.26 116.72 28.26H114.92ZM114.92 26H116.64C117.17 26 117.57 25.87 117.84 25.6C118.12 25.33 118.26 24.95 118.26 24.44C118.26 23.93 118.12 23.51 117.83 23.19C117.54 22.87 117.14 22.72 116.62 22.72H114.91V26H114.92Z"
                      fill="white"
                    />
                    <path
                      id="Vector_122"
                      d="M132.8 31.36C132.42 31.87 131.87 32.32 131.14 32.7C130.41 33.08 129.5 33.28 128.41 33.28C127.02 33.28 125.89 32.8 125.02 31.84C124.15 30.88 123.72 29.63 123.72 28.1V25.46C123.72 23.94 124.14 22.69 124.99 21.73C125.84 20.77 126.93 20.28 128.26 20.28C129.71 20.28 130.79 20.66 131.51 21.41C132.23 22.16 132.64 23.19 132.74 24.49L132.72 24.54H130.27C130.18 23.88 129.99 23.38 129.69 23.04C129.39 22.7 128.94 22.54 128.34 22.54C127.74 22.54 127.24 22.81 126.87 23.35C126.5 23.89 126.31 24.59 126.31 25.45V28.1C126.31 28.98 126.49 29.69 126.86 30.22C127.23 30.76 127.74 31.02 128.4 31.02C128.88 31.02 129.25 30.98 129.53 30.89C129.8 30.8 130.02 30.69 130.18 30.54V28.54H128.41V26.6H132.79V31.35L132.8 31.36Z"
                      fill="white"
                    />
                    <path
                      id="Vector_123"
                      d="M142.61 27.7H138.2V30.85H143.4V33.11H135.59V20.48H143.42V22.74H138.21V25.46H142.62V27.72L142.61 27.7Z"
                      fill="white"
                    />
                    <path
                      id="Vector_124"
                      d="M148.38 28.26V33.1H145.76V20.47H150.08C151.38 20.47 152.41 20.81 153.18 21.49C153.94 22.17 154.32 23.09 154.32 24.27C154.32 24.92 154.17 25.48 153.86 25.95C153.55 26.42 153.1 26.8 152.51 27.09C153.2 27.31 153.69 27.68 154 28.19C154.3 28.7 154.45 29.34 154.45 30.1V30.9C154.45 31.23 154.49 31.61 154.58 32.03C154.67 32.45 154.82 32.75 155.04 32.93V33.1H152.35C152.14 32.92 152.01 32.6 151.95 32.15C151.89 31.69 151.85 31.27 151.85 30.88V30.12C151.85 29.52 151.71 29.06 151.43 28.74C151.15 28.42 150.73 28.26 150.19 28.26H148.39H148.38ZM148.38 26H150.1C150.63 26 151.03 25.87 151.3 25.6C151.58 25.33 151.72 24.95 151.72 24.44C151.72 23.93 151.58 23.51 151.29 23.19C151 22.87 150.6 22.72 150.08 22.72H148.37V26H148.38Z"
                      fill="white"
                    />
                  </g>
                </g>
                <InteractiveGroup id="hamburger">
                  <path
                    id="hamburger_14"
                    d="M78.83 37.1C78.83 37.92 78.17 38.59 77.35 38.59H42.97C42.15 38.59 41.49 37.92 41.49 37.1V34.08C41.49 33.26 42.15 32.59 42.97 32.59H77.35C78.17 32.59 78.83 33.26 78.83 34.08V37.1Z"
                    fill="#F0B54A"
                  />
                  <path
                    id="hamburger_13"
                    d="M78.83 31.11C78.83 31.93 78.17 32.6 77.35 32.6H42.97C42.15 32.6 41.49 31.93 41.49 31.11V30.86C41.49 30.04 42.15 29.37 42.97 29.37H77.35C78.17 29.37 78.83 30.04 78.83 30.86V31.11Z"
                    fill="#E6D7B0"
                  />
                  <path
                    id="hamburger_12"
                    d="M79.84 26.87C79.84 28.25 78.72 29.38 77.35 29.38H42.97C41.59 29.38 40.48 28.26 40.48 26.87C40.48 25.48 41.59 24.36 42.97 24.36H77.35C78.73 24.36 79.84 25.48 79.84 26.87Z"
                    fill="#8B562B"
                  />
                  <g
                    id="hamburger_11"
                    style={{ mixBlendMode: 'multiply' }}
                    opacity="0.67"
                  >
                    <path
                      d="M61.22 24.36L54.4 31.22C54.13 31.49 53.68 31.49 53.41 31.22L46.99 24.36H61.22Z"
                      fill="#CCCCCA"
                    />
                  </g>
                  <path
                    id="hamburger_10"
                    d="M41.32 17.15V25.05C41.32 25.85 41.48 26.33 41.86 26.83C42.12 27.17 42.62 27.29 43.1 27.29C43.58 27.29 44.02 27.14 44.36 26.88L45.09 26.3C45.31 26.13 45.61 26.03 45.92 26.03C46.23 26.03 46.53 26.13 46.75 26.3L47.48 26.88C48.17 27.42 49.3 27.42 49.99 26.88L50.72 26.3C51.18 25.94 51.92 25.94 52.38 26.3L53.11 26.88C53.45 27.14 53.89 27.29 54.37 27.29C54.85 27.29 55.29 27.14 55.63 26.88L56.37 26.3C56.83 25.94 57.58 25.94 58.03 26.3L58.76 26.88C59.45 27.42 60.58 27.42 61.27 26.88L62 26.3C62.46 25.94 63.2 25.94 63.66 26.3L63.81 26.42L64.39 26.88C64.72 27.14 65.17 27.29 65.64 27.29C66.11 27.29 66.56 27.14 66.9 26.88L67.63 26.3C67.85 26.13 68.15 26.03 68.46 26.03C68.77 26.03 69.07 26.13 69.29 26.3L70.02 26.88C70.36 27.14 70.8 27.29 71.28 27.29C71.76 27.29 72.2 27.14 72.54 26.88L73.28 26.3C73.74 25.94 74.48 25.94 74.94 26.3L75.68 26.88C76.01 27.14 76.46 27.29 76.93 27.29C77.4 27.29 77.85 27.14 78.19 26.88C78.19 26.88 79.05 26.23 79.05 24.72V17.16H41.3L41.32 17.15Z"
                    fill="#23993A"
                  />
                  <path
                    id="hamburger_9"
                    d="M63.17 24.48L55.03 32.68C54.67 33.04 54.09 33.04 53.73 32.68L45.59 24.48H63.18H63.17Z"
                    fill="#EDDC5C"
                  />
                  <path
                    id="hamburger_8"
                    d="M80.33 22.68C80.33 23.7 79.51 24.52 78.5 24.52H41.8C40.79 24.52 39.97 23.7 39.97 22.68C39.97 21.66 40.79 20.84 41.8 20.84H78.5C79.51 20.84 80.33 21.66 80.33 22.68Z"
                    fill="#CF411B"
                  />
                  <path
                    id="hamburger_7"
                    d="M81.17 19.59C81.23 20.41 80.56 21.08 79.74 21.08H40.82C40 21.08 39.33 20.41 39.39 19.59C40.14 8.64 49.21 0 60.28 0C71.35 0 80.41 8.64 81.17 19.59Z"
                    fill="#F0B54A"
                  />
                  <path
                    id="hamburger_6"
                    d="M45.89 17.08C45.38 17.59 44.55 17.59 44.04 17.08C43.53 16.57 43.53 15.74 44.04 15.22C44.55 14.71 46.55 14.55 46.55 14.55C46.55 14.55 46.4 16.56 45.89 17.07V17.08Z"
                    fill="#EDDC5C"
                  />
                  <path
                    id="hamburger_5"
                    d="M52.24 7.03C52.75 7.54 52.75 8.38 52.24 8.89C51.73 9.4 50.91 9.4 50.4 8.89C49.89 8.38 49.74 6.37 49.74 6.37C49.74 6.37 51.74 6.52 52.25 7.04L52.24 7.03Z"
                    fill="#EDDC5C"
                  />
                  <path
                    id="hamburger_4"
                    d="M74.66 17.08C75.17 17.59 75.99 17.59 76.5 17.08C77.01 16.57 77.01 15.74 76.5 15.22C75.99 14.71 73.99 14.55 73.99 14.55C73.99 14.55 74.14 16.56 74.65 17.07L74.66 17.08Z"
                    fill="#EDDC5C"
                  />
                  <path
                    id="hamburger_3"
                    d="M58.53 16.16C58.53 16.89 59.11 17.47 59.83 17.47C60.55 17.47 61.13 16.88 61.13 16.16C61.13 15.44 59.83 13.9 59.83 13.9C59.83 13.9 58.53 15.43 58.53 16.16Z"
                    fill="#EDDC5C"
                  />
                  <path
                    id="hamburger_2"
                    d="M61.14 3.22C61.14 2.49 60.56 1.91 59.84 1.91C59.12 1.91 58.54 2.5 58.54 3.22C58.54 3.94 59.84 5.48 59.84 5.48C59.84 5.48 61.14 3.95 61.14 3.22Z"
                    fill="#EDDC5C"
                  />
                  <path
                    id="hamburger_1"
                    d="M68.32 7.03C67.81 7.54 67.81 8.38 68.32 8.89C68.83 9.4 69.65 9.4 70.16 8.89C70.67 8.38 70.82 6.37 70.82 6.37C70.82 6.37 68.82 6.52 68.31 7.04L68.32 7.03Z"
                    fill="#EDDC5C"
                  />
                </InteractiveGroup>
                <g id="Group_18">
                  <g id="Group_19">
                    <path
                      id="Vector_125"
                      d="M183.83 151.77L181.54 149.36C180.85 148.65 179.98 147.74 180.05 145.11V134.41H179.67V145.56C179.67 148.07 180.28 148.93 181.33 150.02C182.87 151.61 183.26 152.23 183.94 152.93V152.07L183.83 151.78V151.77Z"
                      fill="#755925"
                    />
                    <path
                      id="Vector_126"
                      d="M183.2 138.24L184.96 138.29L185.96 166.14C185.96 166.14 185.76 167.73 184 167.73C182.24 167.73 182.1 166.16 182.1 166.16L183.2 138.25V138.24Z"
                      fill="#755925"
                    />
                    <path
                      id="Vector_127"
                      d="M185.47 154.66C186.25 153.85 186.69 152.74 186.69 151.6V137.99H187.29V151.94C187.29 153.74 186.59 155.49 185.36 156.76L185.47 154.65V154.66Z"
                      fill="#755925"
                    />
                    <path
                      id="Vector_128"
                      d="M187.32 146.38L188.96 144.65C189.45 144.14 190.08 143.49 190.02 141.61V133.95H190.29V141.93C190.29 143.73 189.85 144.34 189.1 145.12C188 146.26 187.72 146.7 187.23 147.2V146.58L187.31 146.37L187.32 146.38Z"
                      fill="#C2AE8C"
                    />
                    <path
                      id="Vector_129"
                      d="M204.25 132.18C204.25 129.94 202.69 128.08 200.64 127.69C200.47 123.61 197.23 120.35 193.25 120.35C192.69 120.35 192.14 120.42 191.62 120.54C191.02 118.29 189.05 116.63 186.69 116.63C185.51 116.63 184.43 117.05 183.57 117.74C182.39 116.35 180.66 115.47 178.74 115.47C175.24 115.47 172.41 118.36 172.31 121.95C172.22 121.95 172.12 121.94 172.03 121.94C168.79 121.94 166.16 124.66 166.16 128.02C166.16 128.17 166.16 128.3 166.18 128.45C164.55 129.29 163.42 131.03 163.42 133.04C163.42 135.32 164.86 137.26 166.86 137.92C166.86 138.03 166.84 138.14 166.84 138.26C166.84 143.47 170.91 147.69 175.93 147.69C176.98 147.69 177.99 147.49 178.93 147.15C180.26 148.88 182.28 149.98 184.55 149.98C187.44 149.98 189.93 148.19 191.08 145.6C191.85 145.88 192.68 146.05 193.55 146.05C197.64 146.05 200.95 142.61 200.95 138.37C200.95 137.78 200.88 137.21 200.76 136.65C202.76 136.22 204.25 134.38 204.25 132.18Z"
                      fill="#236A35"
                    />
                    <path
                      id="Vector_130"
                      d="M175.33 128.09C175.33 128 175.33 127.91 175.33 127.82C175.33 125.23 177.36 123.13 179.85 123.13C181.71 123.13 183.3 124.29 184 125.95C184.58 125.44 185.66 124.67 186.35 124.53C192.5 123.21 192.52 130.53 192.71 130.35C192.99 130.1 193.84 126.06 191.13 123.82C187.22 120.58 184.43 122.79 183.8 123.01C183.1 121.36 181.16 119.87 179.31 119.87C176.81 119.87 174.88 121.55 174.88 124.14C174.88 124.23 174.71 125.66 174.72 125.75C173.35 126.05 170.8 127.61 170.8 130.37C170.8 131.29 171 132.17 171.34 132.96C171.73 130.69 173.28 128.84 175.34 128.1L175.33 128.09Z"
                      fill="#31833A"
                    />
                    <path
                      id="Vector_131"
                      d="M185.86 130.83C187.58 131.45 188.7 133.06 188.83 134.84C189.65 134.44 191.25 135.02 191.9 135.25C194.71 136.27 195.61 139.1 194.77 142.1C195.28 141.56 195.69 140.91 195.95 140.15C196.87 137.41 195.48 134.41 192.83 133.45C192.29 133.25 191.73 133.16 191.18 133.16C191.07 131.67 190.13 130.32 188.68 129.8C187.33 129.31 185.9 129.67 184.92 130.61C185.23 130.65 185.54 130.72 185.85 130.83H185.86Z"
                      fill="#31833A"
                    />
                  </g>
                  <g id="Group_20">
                    <path
                      id="Vector_132"
                      d="M176.43 156.6V162.4C176.43 165.48 179.09 167.98 182.36 167.98H186.59C189.86 167.98 192.52 165.48 192.52 162.4V156.6H176.43Z"
                      fill="#B51D23"
                    />
                    <path
                      id="Vector_133"
                      d="M188.74 162.4V156.6H176.43V162.4C176.43 165.48 179.09 167.98 182.36 167.98H182.81C186.08 167.98 188.74 165.48 188.74 162.4Z"
                      fill="#E8352E"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_0_1">
            <rect width="204.25" height="175.42" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Wrapper>
  );
};
